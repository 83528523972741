#userSearch-message {
    position: absolute;
    color: #E92326;
}

#emailSearch-message {
    position: absolute;
    color: #E92326;
}

#subscriptionSearch-message {
    position: absolute;
    color: #E92326;
}