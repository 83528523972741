html, body {
  --mediahuis-color-variant-light-grey-100: #FAFAFA;
  --mediahuis-color-variant-mh-primary: #D3140D;
  --mediahuis-color-variant-mh-secondary: #FF8A17;
  --mediahuis-color-variant-mh-tertiary: #CACACA;

  min-height: 100vh;
}

body {
  background-color: var(--mediahuis-color-variant-light-grey-100);
}

.app-bar {
  background-color: var(--mediahuis-color-variant-mh-tertiary) !important;
}
.mh-logo {
  max-height: 3rem;
}