.error__content__paper {
    --mediahuis-color-variant-grey-60: #696969;
    --mediahuis-color-variant-grey-70: #474747;
}

.error__content__paper__text--dark {
    color: var(--mediahuis-color-variant-grey-70);
}

.error__content__paper__text--light {
    color: var(--mediahuis-color-variant-grey-60);
}

.error__content {
    min-width: 436px;
    max-width: 436px;
}

.error__content__paper {
    border-radius: 10px !important;
    padding: 40px 46px 32px 46px;
}


